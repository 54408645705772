var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ height: 'calc( 100% - 61px )' }),attrs:{"id":"tree-wrap"},on:{"contextmenu":function($event){return _vm.showMenu($event)}}},[_c('v-treeview',{key:_vm.currentFile.ref,attrs:{"open":_vm.open,"items":_vm.items,"item-key":"name","activatable":"","open-on-click":"","dense":"","return-object":"","active":_vm.active},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){return _vm.updateActive($event[0])}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.files[item.name.split('.').pop()] || _vm.files.txt)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.get(item, 'editor.body'))?_c('v-icon',[_vm._v("mdi-circle-medium")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:{ 'text-decoration-underline': item.main && _vm.items.length > 1 },on:{"contextmenu":function($event){$event.stopPropagation();return (function (e) { return _vm.showMenu(e, item); })($event)}}},on),[_vm._v(" "+_vm._s(item.name.split('/').pop())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name.split('/').pop()))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.main),expression:"item.main"}]},[_vm._v("(Entry .ino file)")])])]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}),_c('v-menu',{attrs:{"position-x":_vm.menuX,"position-y":_vm.menuY,"absolute":"","offset-y":"","close-on-click":""},model:{value:(_vm.isMenu),callback:function ($$v) {_vm.isMenu=$$v},expression:"isMenu"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('files-add-file',{attrs:{"project":_vm.currentProject,"path":_vm.menuItem.ref && _vm.menuItem.ref.replace(/[^/]+$/, '')},on:{"open":function($event){_vm.isMenu = false}}}),_c('files-add-folder',{attrs:{"project":_vm.currentProject,"path":_vm.menuItem.ref && _vm.menuItem.ref.replace(/[^/]+$/, '')},on:{"open":function($event){_vm.isMenu = false}}}),(!_vm.menuItem.main && _vm.menuItem._id)?_c('files-add-file',{key:_vm.menuItem.ref,attrs:{"project":_vm.currentProject,"path":_vm.menuItem.ref && _vm.menuItem.ref.replace(/[^/]+$/, ''),"file":_vm.menuItem},on:{"open":function($event){_vm.isMenu = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cursor-text")]),_vm._v(" Rename/Move ")],1)],1)]}}],null,false,4283642857)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.menuItem.main && !_vm.menuItem.root),expression:"!menuItem.main && !menuItem.root"}]},on),[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Delete ")],1)],1)]}}]),model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete "+_vm._s(_vm.menuItem._id ? 'File' : 'Folder')+"? ")]),_c('v-card-text',[_vm._v(" Delete the following "+_vm._s(_vm.menuItem._id ? 'file?' : 'folder and all the sub-files/folders within it?')+" "),_c('br'),_c('code',[_vm._v(" "+_vm._s(_vm.menuItem.ref && _vm.menuItem.ref.split(((_vm.currentProject.ref) + "/")).pop())+" ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.removeFile}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }