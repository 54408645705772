<template>
  <ide
    class="code-editor"
    :value="code"
    :language="language"
    @input="updateCode($event)"
  />
</template>

<script>

import debouncePromise from 'debounce-promise';
import get from 'lodash/get';
import Ide from '../ide.vue';

export default {
  components: {
    Ide,
  },
  computed: {
    settings() {
      const { Setting } = this.$FeathersVuex.api;
      const { data } = Setting.findInStore({ query: { key: 'editor' } });
      if (data[0]) return data[0].value;
      return {};
    },
    currentFile() {
      const { File } = this.$FeathersVuex.api;
      return File.findInStore({ query: { uuid: this.$store.getters.currentFile } }).data[0] || {};
    },
    code() { 
      
      var x = localStorage.getItem("codeText")
      var presentFile = localStorage.getItem("presentFile")
      var currentFile = localStorage.getItem("currentFile")

      // console.log(localStorage.getItem("statusCopyCode"))
      var isCurrentCode = false
      var bodyCode
      if(x != "" && presentFile == currentFile){
        console.log("paste done");
        localStorage.removeItem("codeText");
        
        if(presentFile != currentFile){
          bodyCode = this.currentFile?.body;

          this.$set(this.currentFile, 'body', bodyCode);
          this.save();
        }else{
          if(localStorage.getItem("statusCopyCode") == 1){
            this.currentFile.body = atob(x);
            localStorage.removeItem("presentFile")
            localStorage.setItem("statusCopyCode",0)
          }
          
          bodyCode = this.currentFile.body;

          this.$set(this.currentFile, 'body', bodyCode);
          this.save();
        }
      }else{
        bodyCode = this.currentFile?.body;
      }

      return bodyCode ?? '';
      
    },
    language() {
      switch (get(this.currentFile, 'contentType', 'text/plain')) {
        case 'text/x-arduino':
        case 'text/x-c':
          return 'cpp';
        case 'text/markdown':
          return 'markdown';
        default:
          return '';
      }
    },
  },
  mounted() {
    localStorage.setItem("statusCopyCode",1);
  },
  methods: {
    updateCode(code) {
      this.$set(this.currentFile, 'body', code);
      this.save();
    },
    // eslint-disable-next-line func-names
    save: debouncePromise(async function () {
      await this.currentFile.save();
    }, 500),
  },
};
</script>

<style>
.code-editor {
  width: 100%;
  height: calc(100vh - 89px);
}
</style>